
import { defineComponent, onMounted, computed } from 'vue'
import Button from '@/components/UI/Button.vue'
import { useStore } from '@/store'
import { leaseActionTypes } from '@/store/leaseCategories/actions'
import { VueCookieNext } from 'vue-cookie-next'
import loader from '@/components/UI/Loader.vue'
import { useRouter } from 'vue-router'
import MessageBar from '@/components/UI/MessageBar.vue'
import getCompany from '@/mixins/getCompany'
import ConvertToCommas from '@/filters/ConvertToCommas'
export default defineComponent({
  name: 'Lease Categories',
  components: {
    Button,
    loader,
    MessageBar
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const loader = computed(() => store.state.leaseCategories.loader)
    const roles = VueCookieNext.getCookie('roles')
    const getAllLeaseCategories = computed(() => store.state.leaseCategories.allLeaseCategories)
    const { compId, companyDetailState } = getCompany()
    const initializeRequest = () => {
      store.dispatch(leaseActionTypes.GET_ALLCATEGORIES, { companyId: compId })
    }
    const openNewCategory = () => {
      router.push({ name: 'LeaseCategory' })
    }
    onMounted(async () => {
      await initializeRequest()
    })
    return {
      loader,
      getAllLeaseCategories,
      openNewCategory,
      ConvertToCommas,
      roles,
      companyDetailState
    }
  }
})
